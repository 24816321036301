import React from 'react'

export function Flex ({
  style,
  row, // row => riga, default a column
  children,
  js, // JustifyStart => justifyContent a flex-start
  jb, // JustifyBetween => justifyContent a space-between
  je, // JustifyEnd => justifyContent a flex-end
  as, // AlignStart => alignItems a flex-start
  ae, // AlignEnd => alignItems a flex-end
  width,
  height,
  fw, // FullWidth => width a 100%
  fh, // FullHeight => height a 100%
  bg, // BackgroundColor a red o al colore specificato
  wrap, // flexWrap a wrap
  ...props
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: row ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...(js ? { justifyContent: 'flex-start' } : {}),
        ...(jb ? { justifyContent: 'space-between' } : {}),
        ...(je ? { justifyContent: 'flex-end' } : {}),
        ...(as ? { alignItems: 'flex-start' } : {}),
        ...(ae ? { alignItems: 'flex-end' } : {}),
        ...(fw ? { width: '100%' } : {}),
        ...(fh ? { height: '100%' } : {}),
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
        ...(bg ? { backgroundColor: bg === true ? 'red' : bg } : {}),
        ...(wrap ? { flexWrap: wrap === true ? 'wrap' : wrap } : {}),
        ...style
      }}
      {...props}
    >
      {children}
    </div>
  )
}
