import React from 'react'

export const Badge = ({ value, style = {} }) => (
  <div
    style={{
      paddingRight: 4,
      paddingLeft: 4,
      minWidth: value < 100 && 25,
      height: 25,
      marginLeft: 10,
      borderRadius: value < 100 ? '50%' : '40%',
      backgroundColor: 'red',
      color: 'white',
      textAlign: 'center',
      ...style
    }}
  >
    {value || ''}
  </div>
)
