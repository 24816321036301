import React from 'react'

export const Text = ({ style, title, bold, italic, size, color, center, upCase, value }) => (
  <div
    className='textContainer'
    style={{
      fontWeight: bold ? 'bold' : 'normal',
      fontStyle: italic ? 'italic' : 'normal',
      fontSize: size || 15,
      fontFamily: title ? 'Lato, sans-serif' : '\'Source Sans Pro\', sans-serif',
      ...(color === 'inherit' ? {} : { color: color || '#32324e' }),
      textAlign: center ? 'center' : 'start',
      ...style
    }}
  >
    {upCase ? value.toUpperCase() : value}
  </div>
)
